<template>
  <div class="login">
    <div class="login-container">
      <div class="login-items-container">
        <img
          class="login-item freyda-logo"
          alt="Freyda Icon"
          src="../assets/freyda_logo.svg"
        >
        <router-view class="login-item" />
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Login',
};
</script>
